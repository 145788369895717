/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 *   IMPORTANT: This file is used by the internal build
 *   script `extract-intl`, and must use CommonJS module syntax
 *   You CANNOT use import/export in this file.
 */

if (!Intl.PluralRules) {
  // eslint-disable-next-line global-require
  require('@formatjs/intl-pluralrules/polyfill')
}

if (!Intl.RelativeTimeFormat) {
  // eslint-disable-next-line global-require
  require('@formatjs/intl-relativetimeformat/polyfill')
}

// const { addLocaleData } = require('react-intl')
// const enLocaleData = require('react-intl/locale-data/en')
// const deLocaleData = require('react-intl/locale-data/de')
// const viLocaleData = require('react-intl/locale-data/vi')

// eslint-disable-next-line @typescript-eslint/no-var-requires
const enTranslationMessages = require('./translations/en.json')
// eslint-disable-next-line @typescript-eslint/no-var-requires
const deTranslationMessages = require('./translations/de.json')
// eslint-disable-next-line @typescript-eslint/no-var-requires
const viTranslationMessages = require('./translations/vi.json')

// addLocaleData(enLocaleData)
// addLocaleData(deLocaleData)
// addLocaleData(viLocaleData)

const DEFAULT_LOCALE = 'en'

// prettier-ignore
const appLocales = [
  'en',
  'de',
  'vi',
]

const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages = locale !== DEFAULT_LOCALE
    ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages)
    : {}
  const flattenFormattedMessages = (formattedMessages, key) => {
    const formattedMessage = !messages[key] && locale !== DEFAULT_LOCALE
      ? defaultFormattedMessages[key]
      : messages[key]
    return Object.assign(formattedMessages, { [key]: formattedMessage })
  }
  return Object.keys(messages).reduce(flattenFormattedMessages, {})
}

const translationMessages = {
  en: formatTranslationMessages('en', enTranslationMessages),
  de: formatTranslationMessages('de', deTranslationMessages),
  vi: formatTranslationMessages('vi', viTranslationMessages),
}

exports.appLocales = appLocales
exports.formatTranslationMessages = formatTranslationMessages
exports.translationMessages = translationMessages
exports.DEFAULT_LOCALE = DEFAULT_LOCALE
