/**
 * Asynchronously loads the component for Insight
 */

import React from 'react'
import loadable from 'utils/loadable'
import LoadingIndicator from 'components/LoadingIndicator'

export default loadable(() => import('./index'), {
  fallback: <div className="w-60 ml-20 mr-20 mt-20"><LoadingIndicator /></div>,
})
