import produce from 'immer'
import { eraseCookieLocalStorage, getCookie } from 'utils/cookie'
import jwt from 'jsonwebtoken'

export const initialState = {
  loggedIn: false,
  userData: null,
}

const extractUserDataFromToken = (token) => {
  const parsedJWT = jwt.decode(token)
  return {
    firstName: (parsedJWT.dn.split(',')[0].split('=')[1]).replace(/ *\([^)]*\) */g, ''),
  }
}

const cookieValue = getCookie('zykrr')
if (cookieValue) {
  try {
    initialState.userData = extractUserDataFromToken(cookieValue)
    if (window.gtag && initialState.userData.firstName) {
      window.gtag('config', {
        user_id: initialState.userData.firstName,
      })
    }
    initialState.loggedIn = true
  } catch (error) {
    // any error in parsing the token means the the token is malformed
    // console.error(error)
    eraseCookieLocalStorage('zykrr')
  }
}

export const actions = { SIGNIN: 'SIGNIN', SIGNOUT: 'SIGNOUT' }

/* eslint-disable default-case, no-param-reassign */
const appReducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case 'SIGNIN': {
      draft.loggedIn = true
      draft.userData = extractUserDataFromToken(action.payload.token)
      break
    }
    case 'SIGNOUT':
      draft.loggedIn = false
      draft.userData = null
      break
  }
})

export default appReducer
