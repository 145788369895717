import { useSnackbar, OptionsObject } from 'notistack'
import React from 'react'

export enum VariantType {
  default = 'default',
  error = 'error',
  success = 'success',
  warning = 'warning',
  info = 'info'
}

interface SnackProps {
  setUseSnackbarRef: (showSnackbar: any) => void
}

const InnerSnackbarUtilsConfigurator: React.FC<SnackProps> = (props) => {
  props.setUseSnackbarRef(useSnackbar())
  return null
}

let useSnackbarRef: any
const setUseSnackbarRef = (useSnackbarRefProp: any) => {
  useSnackbarRef = useSnackbarRefProp
}

export const SnackbarUtilsConfigurator = (props: {
  children?: React.ReactNode
}) => (
  <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef}>
    {props.children}
  </InnerSnackbarUtilsConfigurator>
)

// sets a default length for all Snack messages
const defaultSnackMessageLength = 1000

const trimMessage = (
  msg: string,
  length: number = defaultSnackMessageLength,
) => msg.substring(0, length)

const Notification = {
  success(msg: string, options: OptionsObject = {}) {
    this.toast(trimMessage(msg), { ...options, variant: VariantType.success })
  },
  warning(msg: string, options: OptionsObject = {}) {
    this.toast(trimMessage(msg), { ...options, variant: VariantType.warning })
  },
  info(msg: string, options: OptionsObject = {}) {
    this.toast(trimMessage(msg), { ...options, variant: VariantType.info })
  },
  error(msg: string, options: OptionsObject = {}) {
    this.toast(trimMessage(msg), { ...options, variant: VariantType.error })
  },
  toast(msg: string, options: OptionsObject = {}) {
    useSnackbarRef.enqueueSnackbar(msg, options)
  },
}

export default Notification
