import { cx } from 'emotion'
import React from 'react'
import { Button } from '@mui/material'

interface IProps {
  message?: string
}

interface IState {
  hasError: boolean
  newVersion: boolean
  countDown: number
}

class ErrorBoundary extends React.Component<IProps, IState> {
  timer: NodeJS.Timeout | number | undefined

  constructor(props: IProps) {
    super(props)
    this.state = { hasError: false, newVersion: false, countDown: 5 }
  }

  static getDerivedStateFromError(error) { // Update state so the next render will show the fallback UI.
    let newVersion = false
    // when new version is deployed, webpack file hashes change. Clients having old app will get this error when
    // trying to fetch a non-visited lazy-loadable-page. Check for that error to detect if new application version has been deployed.
    if (/Loading chunk [\d]+ failed/.test(error.message)) {
      newVersion = true
    }
    return { hasError: true, newVersion }
  }

  // componentDidCatch(error, errorInfo) { // You can also log the error to an error reporting service
  //   logErrorToMyService(error, errorInfo);
  // }

  componentDidUpdate(prevProps: IProps, prevState: IState): void {
    if (prevState.newVersion === false && this.state.newVersion === true) {
      this.reloadWindowWithCountdown()
    }
  }

  componentWillUnmount(): void {
    if (this.timer) {
      clearInterval(this.timer as number)
    }
  }

  reloadWindowWithCountdown = (): void => {
    this.timer = setInterval(() => this.setState((state): any => {
      if (state.countDown > 1) {
        return { countDown: state.countDown - 1 }
      }
      window.location.reload()
      return {}
    }), 1000)
  }

  render() {
    if (this.state.hasError) {
      if (this.state.newVersion) {
        return (
          <div className={cx('flex flex-col justify-center items-center text-center h-screen')}>
            <h1>A new version has been released. Please refresh the page.</h1>
            {this.state.newVersion && (
              <Button onClick={() => window.location.reload()} color="primary" variant="contained" className="my-4">
                Refresh
                {' '}
                (
                {this.state.countDown}
                )
              </Button>
            )}
            (Current version:
            {' '}
            {process.env.SOURCE_COMMIT}
            )
          </div>
        )
      }

      return (
        <div className={cx('flex justify-center items-center text-center', this.props.message ? '' : 'h-screen')}>
          <h1>{this.props.message || 'Page crashed. Please go to previous page and reload the page. If it still crashes, close the current tab and revisit the application.'}</h1>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
