import Highcharts from 'highcharts'
import HighchartsExport from 'highcharts/modules/exporting'
import HighchartsOfflineExport from 'highcharts/modules/offline-exporting'
import HighchartsExportData from 'highcharts/modules/export-data'
import HighchartsSunburst from 'highcharts/modules/sunburst'
import HighchartsSankey from 'highcharts/modules/sankey'
import HighchartsDrilldown from 'highcharts/modules/drilldown'
import HighchartsAccessibility from 'highcharts/modules/accessibility'
import HighchartsHeatmap from 'highcharts/modules/heatmap'
import HighchartsAnnotations from 'highcharts/modules/annotations' 
import { formateMessage } from 'containers/Insight/utils'

HighchartsSunburst(Highcharts)
HighchartsAccessibility(Highcharts)
HighchartsHeatmap(Highcharts)
HighchartsSankey(Highcharts)
HighchartsDrilldown(Highcharts)
HighchartsAnnotations(Highcharts)

// init export module: https://www.highcharts.com/docs/export-module/export-module-overview
HighchartsExport(Highcharts)
HighchartsOfflineExport(Highcharts)
HighchartsExportData(Highcharts)

declare module 'highcharts' {
  interface LangOptions {
    chartContextMenuCustom?: string;
  }
}
// disable animation on smaller screens
// we assume that smaller screen implies mobile devices, avoid glitches because of animation
// https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia
let shouldAnimate = window.matchMedia('(min-width: 640px)').matches

//  Highcharts.createElement('link', {
//      href: 'https://fonts.googleapis.com/css?family=Unica+One',
//      rel: 'stylesheet',
//      type: 'text/css'
//  }, null, document.getElementsByTagName('head')[0])

// high charts dark theme
const theme: Highcharts.Options = {
  chart: {
    style: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: '0.85rem'
    },
  },
  title: {
    style: {
      fontSize: '1.25rem',
      color: 'rgba(0, 0, 0, 0.64)',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
  },
  subtitle: {
    style: {
      color: '#718096',
    },
  },
  colors: ["#439aeb", "#434348", "#90ed7d", "#f7a35c", "#8085e9", "#f15c80", "#e4d354", "#2b908f", "#f45b5b", "#91e8e1"],
  // title: {
  //   style: {
  //     // color: '#E0E0E3',
  //     //  textTransform: 'uppercase',
  //     //  fontSize: '20px'
  //   }
  // },
  // subtitle: {
  //   style: {
  //     color: '#E0E0E3',
  //     //  textTransform: 'uppercase'
  //   }
  // },
  xAxis: {
    // gridLineColor: '#707073',
    labels: {
      style: {
        // color: '#E0E0E3'
        fontSize: '0.85rem'
      }
    },
    // lineColor: '#707073',
    // minorGridLineColor: '#505053',
    // tickColor: '#707073',
    // title: {
    //   style: {
    //     color: '#A0A0A3'
    //   }
    // }
  },
  yAxis: {
    //   gridLineColor: '#707073',
    labels: {
      style: {
        // color: '#E0E0E3'
        fontSize: '0.85rem'
      }
    },
    stackLabels: {
      style: {
        color: '#666666',
        fontSize: '0.85rem',
        fontWeight: 'normal'
      },
    },
    //   lineColor: '#707073',
    //   minorGridLineColor: '#505053',
    //   tickColor: '#707073',
    //   tickWidth: 1,
    //   title: {
    //     style: {
    //       color: '#A0A0A3'
    //     }
    //   }
  },
  tooltip: {
    //   backgroundColor: 'rgba(0, 0, 0, 0.85)',
    style: {
      // color: '#F0F0F0',
      fontSize: '0.85rem',
    }
  },
  plotOptions: {
    bar: {
      color: '#439aeb',
    },
    column: {
      color: '#439aeb',
    },
    spline: {
      color: '#434348',
      marker: {
        lineColor: '#434348',
        fillColor: 'white',
        lineWidth: 2,
      },
    },
    series: {
      animation: shouldAnimate,
      dataLabels: {
        // color: '#F0F0F3',
        shadow: false,
        style: {
          color: 'white',
          fontSize: '0.85rem',
          align: 'center',
          textOutline: '0px',
          fontWeight: 'normal',
        }
      },
      // marker: {
      //   lineColor: '#333'
      // }
    },
    // boxplot: {
    //   fillColor: '#505053'
    // },
    // candlestick: {
    //   lineColor: 'white'
    // },
    // errorbar: {
    //   color: 'white'
    // }
  },
  exporting: {
    fallbackToExportServer: false,
    error: () => alert('Not supported'),
    buttons: {
      contextButton: {
        menuItems: ['downloadXLS', 'downloadCSV', 'downloadPNG', 'downloadSVG', 'viewFullscreen'], // enable only these export methods
        titleKey: 'chartContextMenuCustom'
      },
    },
  },
  lang: {
    chartContextMenuCustom: formateMessage('Chart context menu', 'Chart context menu') // Define the custom tooltip text
  },
  legend: {
    itemStyle: {
      color: 'rgba(0, 0, 0, 0.64)',
    }
  },
  // legend: {
  //   backgroundColor: 'rgba(0, 0, 0, 0.5)',
  //   itemStyle: {
  //     color: '#E0E0E3'
  //   },
  //   itemHoverStyle: {
  //     color: '#FFF'
  //   },
  //   itemHiddenStyle: {
  //     color: '#606063'
  //   },
  //   title: {
  //     style: {
  //       color: '#C0C0C0'
  //     }
  //   }
  // },
  // credits: {
  //   style: {
  //     color: '#666'
  //   }
  // },
  // // labels: {
  // //   style: {
  // //     color: '#707073'
  // //   }
  // // },
  // drilldown: {
  //   activeAxisLabelStyle: {
  //     color: '#F0F0F3'
  //   },
  //   activeDataLabelStyle: {
  //     color: '#F0F0F3'
  //   }
  // },
  // navigation: {
  //   buttonOptions: {
  //     symbolStroke: '#DDDDDD',
  //     theme: {
  //       fill: '#505053'
  //     }
  //   }
  // },
  // // scroll charts
  // rangeSelector: {
  //   buttonTheme: {
  //     fill: '#505053',
  //     stroke: '#000000',
  //     style: {
  //       color: '#CCC'
  //     },
  //     states: {
  //       hover: {
  //         fill: '#707073',
  //         stroke: '#000000',
  //         style: {
  //           color: 'white'
  //         }
  //       },
  //       select: {
  //         fill: '#000003',
  //         stroke: '#000000',
  //         style: {
  //           color: 'white'
  //         }
  //       }
  //     }
  //   },
  //   inputBoxBorderColor: '#505053',
  //   inputStyle: {
  //     backgroundColor: '#333',
  //     color: 'silver'
  //   },
  //   labelStyle: {
  //     color: 'silver'
  //   }
  // },
  // navigator: {
  //   handles: {
  //     backgroundColor: '#666',
  //     borderColor: '#AAA'
  //   },
  //   outlineColor: '#CCC',
  //   maskFill: 'rgba(255,255,255,0.1)',
  //   series: {
  //     color: '#7798BF',
  //     lineColor: '#A6C7ED'
  //   },
  //   xAxis: {
  //     gridLineColor: '#505053'
  //   }
  // },
  // scrollbar: {
  //   barBackgroundColor: '#808083',
  //   barBorderColor: '#808083',
  //   buttonArrowColor: '#CCC',
  //   buttonBackgroundColor: '#606063',
  //   buttonBorderColor: '#606063',
  //   rifleColor: '#FFF',
  //   trackBackgroundColor: '#404043',
  //   trackBorderColor: '#404043'
  // }
}

// Apply the theme
Highcharts.setOptions(theme)
