/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import history from 'utils/history'
import authenticationReducer from 'containers/SignIn/reducer'
import languageProviderReducer from 'containers/LanguageProvider/reducer'
import globalFiltersReducer from 'components/data-filter/reducer'
import userTableQuestionsReducer from 'containers/Insight/UserView/SpecificUserProfile/reducer'
import campaignDetailsReducer from 'containers/Insight/reducer'
import participantInfoReducer from 'store/reducers/ParticipantInfo'
import participantListUploadReducer from 'store/reducers/ParticipantUpload'
import participantListReducer from 'store/reducers/ParticipantList'

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    auth: authenticationReducer,
    language: languageProviderReducer,
    globalFilters: globalFiltersReducer,
    userSpecificTabConfig: userTableQuestionsReducer,
    currentCampaign: campaignDetailsReducer,
    router: connectRouter(history),
    participantInfo: participantInfoReducer,
    participantListUpload: participantListUploadReducer,
    participantList: participantListReducer,
    ...injectedReducers,
  })

  return rootReducer
}
