export default {
  // Participant info
  participantInfoStart: 'PARTICIPANT_INFO_START',
  participantInfoFailure: 'PARTICIPANT_INFO_FAILURE',
  participantInfoSuccess: 'PARTICIPANT_INFO_SUCCESS',
  participantListUpload: 'PARTICIPANT_LIST_UPLOAD',
  participantListUploadSuccess: 'PARTICIPANT_LIST_UPLOAD_SUCCESS',
  participantListUploadFailure: 'PARTICIPANT_LIST_UPLOAD_FAILURE',
  participantListUploadStatus: 'PARTICIPANT_LIST_UPLOAD_STATUS',
  participantListStart: 'PARTICIPANT_LIST_START',
  participantListFailure: 'PARTICIPANT_LIST_FAILURE',
  participantListSuccess: 'PARTICIPANT_LIST_SUCCESS',
}
