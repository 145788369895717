import produce from 'immer'
import { Action } from 'redux'
import actionTypes from '../actions/types'

interface IAction extends Action {
  payload: {
    data: any,
  }
}

interface IReducerState {
  participantList: { [key: string]: any }
}

const initialState: IReducerState = {
  participantList: {
    isFetching: false,
    hasError: false,
    data: {},
  },
}

const participantListReducer = (state = initialState, action: IAction) =>
  produce(state, draft => {
    switch (action.type) {
      case actionTypes.participantListStart: {
        draft.participantList = {
          ...draft.participantList,
          isFetching: true,
          hasError: false,
        }
        break
      }
      case actionTypes.participantListFailure: {
        draft.participantList = {
          ...draft.participantList,
          isFetching: false,
          hasError: true,
        }
        break
      }
      case actionTypes.participantListSuccess: {
        draft.participantList = {
          ...draft.participantList,
          isFetching: false,
          hasError: true,
          data: action.payload.data,
        }
        break
      }
      default:
    }
  })


export default participantListReducer
