import produce from 'immer'
import { Action } from 'redux'
import actionTypes from '../actions/types'

interface IAction extends Action {
  payload: {
    apiName: string,
    data: any,
  }
}

interface IReducerState {
    participantListUpload: { [key: string]: any }
    participantListUploadStatus: { [key: string]: any }
}

const initialState: IReducerState = {
  participantListUpload: {
    isUploading: {},
    hasError: {},
    data: {},
  },
  participantListUploadStatus: {
    status: '',
    hasError: false,
    errorMsg: '',
  },
}

const participantListUploadReducer = (state = initialState, action: IAction) => produce(state, (draft) => {
  switch (action.type) {
    case actionTypes.participantListUpload: {
      draft.participantListUpload = {
        ...draft.participantListUpload,
        isUploading: {
          ...draft.participantListUpload.isListUploading,
          [action.payload.apiName]: true,
        },
        hasError: {
          ...draft.participantListUpload.hasError,
          [action.payload.apiName]: false,
        },
      }
      break
    }
    case actionTypes.participantListUploadSuccess: {
      draft.participantListUpload = {
        ...draft.participantListUpload,
        isUploading: {
          ...draft.participantListUpload.isUploading,
          [action.payload.apiName]: false,
        },
        hasError: {
          ...draft.participantListUpload.hasError,
          [action.payload.apiName]: false,
        },
      }
      break
    }
    case actionTypes.participantListUploadFailure: {
      draft.participantListUpload = {
        ...draft.participantListUpload,
        isFetching: {
          ...draft.participantListUpload.isUploading,
          [action.payload.apiName]: false,
        },
        hasError: {
          ...draft.participantListUpload.hasError,
          [action.payload.apiName]: true,
        },
      }
      break
    }
    case actionTypes.participantListUploadStatus: {
      draft.participantListUploadStatus = {
        ...draft.participantListUploadStatus,
        status: action.payload.data[0].status,
        hasError: action.payload.data[0].error !== null,
        errorMsg: action.payload.data[0].error,
      }
      break
    }
    default:
  }
})


export default participantListUploadReducer
