import produce from 'immer'
import {
  addDays,
  endOfDay,
  startOfDay,
} from 'date-fns'
import { getGMT, IQuestionnaireComponent } from 'containers/Insight/utils'
import { IMetricConfig } from 'graphql/graphql.d'
import { QuestionFilterJSON, allSelectionValue } from './index'
import { startDateRelativeFromToday, checkDefaultDateTime } from './util'

interface ReducerState {
  timeRangeStartDate: any
  timeRangeEndDate: any
  isFilterDrawerVisible: boolean
  dimension: Array<{ name: string, values: string[] }>
  questionFilterAllOptions: { [key: string]: { label: string, value: string }[] } // to store all available options for applying filters.
  appliedFilters: {
    questionFilter: QuestionFilterJSON, // for-currently applied filters
    npsScoreFilter: {
      id: string
      values: Array<string>
    }
    responseType: {
      id: string
      values: Array<string>
    }
  }
  globalResponseSearchTextFilter: unknown
  hasNetPromoterScoreQuestion: boolean
  getDimensionAndUniqueAnswers: Array<{label: string, questionid: string, shortCode: string, values: {label: string, value: string}[]}>
  metricConfig: IMetricConfig[]
  questionnaire: IQuestionnaireComponent[]
  timeZone: string
  initialDimension: Array<{label: string, questionid: string, shortCode: string, values: {label: string, value: string}[]}>
  segmentFilter: {
    segmentName: string
    segmentValue: string[]
    allValues: string[]
  }
  segmentLevels: Array<
    {
      segmentName: string,
      segmentValue: string[],
      selectedSegmentValues: string[],
    }
  >
  overviewTabData: any
  campaignCreationDate: any
}

const initialState: ReducerState = {
  timeRangeStartDate: '',
  timeRangeEndDate: '',
  dimension: [],
  questionFilterAllOptions: {},
  isFilterDrawerVisible: false,
  appliedFilters: {
    questionFilter: {},
    npsScoreFilter: {
      id: '',
      values: ['PROMOTER', 'DETRACTOR', 'PASSIVE', allSelectionValue],
    },
    responseType: {
      id: '',
      values: ['SUBMIT', 'PARTIAL', allSelectionValue],
    },
  },
  globalResponseSearchTextFilter: {},
  getDimensionAndUniqueAnswers: [],
  hasNetPromoterScoreQuestion: false,
  metricConfig: [],
  questionnaire: [],
  timeZone: '',
  initialDimension: [],
  segmentFilter: {
    segmentName: '',
    segmentValue: [],
    allValues: [],
  },
  segmentLevels: [],
  overviewTabData: {},
  campaignCreationDate: '',
}
const globalFilterReducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case 'CHANGE_TIMERANGE': {
      draft.overviewTabData = {
        campaign: {
          escalationCount: {
            openEscalation: 0,
            closeEscalation: 0,
            totalEscalation: 0,
          },
          hasNetSatisfactionScoreQuestion: false,
          netSatisfactionScore: null,
          cesScoreMean: null,
          hasCESQuestion: false,
          isEscalationConfigured: true,
          numberOfResponses: 0,
          netPromoterScore: [
            {
              segment: 'PROMOTERS',
              count: 0,
            },
            {
              segment: 'PASSIVES',
              count: 0,
            },
            {
              segment: 'DETRACTORS',
              count: 0,
            },
          ],
          type: 'GENERAL',
          benchmark: [],
          huddleConfiguration: true,
        },
      }
      draft.timeRangeStartDate = action.payload.startDate
      draft.timeRangeEndDate = action.payload.endDate
      break
    }
    case 'LOAD_GLOBAL_FILTER': {
      const arr = {}
      // action.payload.getDimensionAndUniqueAnswers.forEach((x) => {
      //   arr[x.questionid] = x.options
      // })
      const npsQu = action.payload.questionnaire.find((x) => x.isNPS)
      draft.questionFilterAllOptions = arr
      draft.campaignCreationDate = startOfDay(new Date(action.payload.createdAt))
      // reset others
      const dateRange = checkDefaultDateTime(action.payload.defaultDateFilterRange)
      draft.timeRangeStartDate = startOfDay(dateRange[0])
      draft.timeRangeEndDate = endOfDay(dateRange[1])
      draft.dimension = []
      // draft.appliedFilters.questionFilter = {}
      if (npsQu) {
        const ratingScaleOpts = ['PROMOTER', 'PASSIVE', 'DETRACTOR']
        draft.appliedFilters.npsScoreFilter.id = npsQu.questionId
        draft.appliedFilters.npsScoreFilter.values = [...ratingScaleOpts, allSelectionValue]
      }
      draft.globalResponseSearchTextFilter = {}
      // draft.getDimensionAndUniqueAnswers = action.payload.getDimensionAndUniqueAnswers
      // draft.initialDimension = action.payload.getDimensionAndUniqueAnswers
      draft.hasNetPromoterScoreQuestion = action.payload.hasNetPromoterScoreQuestion
      draft.metricConfig = action.payload.metricConfig
      draft.questionnaire = action.payload.questionnaire
      draft.timeZone = `${new Date().getTimezoneOffset() < 0 ? '-' : '+'}${getGMT(new Date().getTimezoneOffset())}`
      draft.appliedFilters.questionFilter = {}
      draft.segmentFilter.segmentName = ''
      draft.segmentFilter.segmentValue = []
      draft.segmentFilter.allValues = []
      draft.segmentLevels = []
      break
    }
    case 'CLEAR_GLOBAL_RESPONSE_FILTER': {
      draft.globalResponseSearchTextFilter = {}
      break
    }
    case 'LOAD_GLOBAL_RESPONSE_FILTER': {
      draft.globalResponseSearchTextFilter = action.payload.param
      break
    }
    case 'CLEAR_GLOBAL_FILTER': {
      // draft.getDimensionAndUniqueAnswers = state.initialDimension
      draft.appliedFilters.questionFilter = {}
      if (draft.appliedFilters.npsScoreFilter.id) {
        draft.appliedFilters.npsScoreFilter.values = ['PROMOTER', 'DETRACTOR', 'PASSIVE', allSelectionValue]
      }
      break
    }
    case 'CHANGE_GLOBAL_FILTER_VISIBILTY': {
      draft.isFilterDrawerVisible = action.payload
      break
    }
    case 'CHANGE_NPS_SCORE_FILTER': {
      draft.appliedFilters.npsScoreFilter.values = action.payload
      break
    }
    case 'CHANGE_RESPONSE_TYPE_FILTER': {
      draft.appliedFilters.responseType.values = action.payload
      break
    }
    case 'CHANGE_GLOBAL_FILTER_AFTER_APPLY': {
      draft.appliedFilters.questionFilter = action.payload
      break
    }
    case 'CHANGE_FILTER_OPTIONS': {
      draft.getDimensionAndUniqueAnswers = action.payload.campaign.getDimensionAndUniqueAnswers
      if (action.payload.getCampaignsEnabledForPrecalculation.includes(action.payload.campaignId) && (state.segmentFilter.segmentName === '' || state.segmentFilter.segmentValue?.length === 0)) {
        draft.segmentFilter.segmentName = action.payload.campaign.getDimensionAndUniqueAnswers[0]?.attributeName
        let segmentLevelsObj
        if (!action.payload.campaign.getDimensionAndUniqueAnswers[0]?.options.find((val) => val.label === 'ALL')) {
          const segLabels = action.payload.campaign.getDimensionAndUniqueAnswers[0]?.options.map((idx) => idx.value)
          draft.segmentFilter.segmentValue = segLabels
          segmentLevelsObj = {
            segmentName: action.payload.campaign.getDimensionAndUniqueAnswers[0]?.attributeName,
            segmentValue: ['ALL'],
            selectedSegmentValues: ['ALL'],
          }
        } else {
          draft.segmentFilter.segmentValue = [action.payload.campaign.getDimensionAndUniqueAnswers[0]?.options[0]?.value]
          segmentLevelsObj = {
            segmentName: action.payload.campaign.getDimensionAndUniqueAnswers[0]?.attributeName,
            segmentValue: [action.payload.campaign.getDimensionAndUniqueAnswers[0]?.options[0]?.value],
            selectedSegmentValues: [action.payload.campaign.getDimensionAndUniqueAnswers[0]?.options[0]?.value],
          }
        }
        draft.segmentLevels.push(segmentLevelsObj)
        const allValues = action.payload.campaign.getDimensionAndUniqueAnswers[0]?.options.map((v) => v.value) || []
        if (allValues.includes('ALL')) {
          draft.segmentFilter.allValues = ['ALL']
        } else {
          draft.segmentFilter.allValues = allValues
          const questionFilterObj = {
            [action.payload.campaign.getDimensionAndUniqueAnswers[0]?.campaignId]: {
              // eslint-disable-next-line max-len
              [action.payload.campaign.getDimensionAndUniqueAnswers[0]?.questionid]: action.payload.campaign.getDimensionAndUniqueAnswers[0]?.options.map((x: { value: string }) => x.value),
            },
          }
          draft.appliedFilters.questionFilter = questionFilterObj
        }
      }
      break
    }
    case 'CHANGE_SEGMENT': {
      draft.overviewTabData = {
        campaign: {
          escalationCount: {
            openEscalation: 0,
            closeEscalation: 0,
            totalEscalation: 0,
          },
          hasNetSatisfactionScoreQuestion: false,
          netSatisfactionScore: null,
          cesScoreMean: null,
          hasCESQuestion: false,
          isEscalationConfigured: true,
          numberOfResponses: 0,
          netPromoterScore: [
            {
              segment: 'PROMOTERS',
              count: 0,
            },
            {
              segment: 'PASSIVES',
              count: 0,
            },
            {
              segment: 'DETRACTORS',
              count: 0,
            },
          ],
          type: 'GENERAL',
          benchmark: [],
          huddleConfiguration: true,
        },
      }
      draft.segmentFilter.segmentName = action.payload.segmentName
      draft.segmentFilter.segmentValue = action.payload.segmentValue
      draft.segmentFilter.allValues = action.payload.allValues
      draft.segmentLevels = action.payload.segmentLevels
      break
    }
    case 'LOAD_DATA_FOR_OVERVIEW': {
      draft.overviewTabData = action.payload
      break
    }
    default:
  }
})

export default globalFilterReducer
