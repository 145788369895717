import dayjs, { Dayjs } from 'dayjs'
import { RangeValueType } from 'rc-picker/lib/PickerInput/RangePicker'
// import { findThisAndLastWeek } from './dateRangeSelector'

export const startDateRelativeFromToday = -29

export const predefinedRanges = [
  'Today',
  'This week',
  'Last week',
  'This month',
  'Last 30 days',
  'Last month',
  'This year',
  'This FY',
  'Last FY',
]

export const findThisAndLastWeek = (): {[key: string]: RangeValueType<Dayjs>} => {
  const currentDate = new Date()
  const getCurrentDay = currentDate.getDay()
  let lastAndThisWeek: {[key: string]: RangeValueType<Dayjs>} = {
    this_week: [dayjs().startOf('week').add(1, 'd'), dayjs().endOf('day')],
    last_week: [dayjs().startOf('week').subtract(1, 'week').add(1, 'd'), dayjs().startOf('week').endOf('day')],
  }

  if (getCurrentDay === 0) {
    lastAndThisWeek = {
      this_week: [dayjs().startOf('week').subtract(1, 'w').add(1, 'd'), dayjs().endOf('day')],
      last_week: [dayjs().startOf('week').subtract(2, 'w').add(1, 'd'), dayjs().startOf('week').subtract(1, 'w').endOf('day')],
    }
  }
  return lastAndThisWeek
}

export const checkDefaultDateTime = (defaultDate: string): [Date, Date] => {
  let tempDateObj: RangeValueType<Dayjs> = [dayjs().startOf('day'), dayjs().endOf('day')]

  const index = predefinedRanges.indexOf(defaultDate)
  if (index === 0) {
    tempDateObj = [dayjs().startOf('day'), dayjs().endOf('day')]
  } else if (index === 1) {
    tempDateObj = findThisAndLastWeek().this_week // [dayjs().startOf('week').add(1, 'd'), dayjs().endOf('day')]
  } else if (index === 2) {
    tempDateObj = findThisAndLastWeek().last_week // [dayjs().startOf('week').subtract(1, 'week').add(1, 'd'), dayjs().startOf('week').endOf('day')]
  } else if (index === 3) {
    tempDateObj = [dayjs().startOf('month'), dayjs().endOf('day')]
  } else if (index === 4) {
    tempDateObj = [dayjs().startOf('day').add(-30, 'd'), dayjs().endOf('day')]
  } else if (index === 5) {
    tempDateObj = [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')]
  } else if (index === 6) {
    tempDateObj = [dayjs().startOf('year'), dayjs().endOf('day')]
  } else if (index === 7) {
    tempDateObj = [dayjs().month(3).startOf('month'), dayjs().endOf('day')]
  } else if (index === 8) {
    tempDateObj = [dayjs().startOf('year').subtract(1, 'year').month(3), dayjs().startOf('year').add(2, 'month').endOf('month')]
  }
  const startDate = new Date((tempDateObj[0] ?? dayjs()).format('YYYY-MM-DD'))
  const endDate = new Date((tempDateObj[1] ?? dayjs()).format('YYYY-MM-DD'))

  return [startDate, endDate]
}

export const displayFilterValue = (value: string[]): string => {
  if (value.length > 1) {
    return `(${value.length}) values`
  }
  if (!value[0]) {
    return ''
  }
  return value[0].length <= 8 ? value[0] : `${value[0].slice(0, 8)}...`
}
