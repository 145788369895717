import { ApolloClient, HttpLink, from } from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from 'apollo-link-context'
import { getCookie } from 'utils/cookie'

const authLink = setContext((_, { headers }) => {
  // Get the cookie value
  const value = getCookie('zykrr')

  // Return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      zykrr: value,
    },
  }
})

const client = new ApolloClient({
  name: 'insights',
  version: '3.0.0',
  connectToDevTools: process.env.NODE_ENV !== 'production',
  link: from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({
          message, locations, path, extensions,
        }) => {
          if (extensions?.code === 'UNAUTHENTICATED') {
            window.location.href = `${process.env.AUTH_HOST}/sign_in?continue=${encodeURI(
              window.location.href,
            )}&reauth=true`
            return
          } if (message === 'Context creation failed: DEVICE_MISMATCH') {
            window.location.href = `${process.env.AUTH_HOST}/sign_in?continue=${encodeURI(
              window.location.href,
            )}&reauth=true&error=DEVICE_MISMATCH`
            return
          }
          if (message === 'Context creation failed: TOKEN_EXPIRED') {
            window.location.href = `${process.env.AUTH_HOST}/sign_in?continue=${encodeURI(
              window.location.href,
            )}&reauth=true&error=TOKEN_EXPIRED`
            return
          }
          console.log( // eslint-disable-line no-console
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          )
        })
      }
      if (networkError) console.log(`[Network error]: ${networkError}`) // eslint-disable-line no-console
    }),
    authLink,
    createUploadLink({
      uri: `${process.env.BACKEND_HOST}/graphql/`,
      credentials: 'include',
    }),
  ]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          campaign: {
            merge: true,
          },
        },
      },
    },
  }),
  // defaultOptions: {
  //   query: {
  //     fetchPolicy: 'no-cache',
  //     errorPolicy: 'all',
  //   },
  //   watchQuery: {
  //     fetchPolicy: 'no-cache',
  //     errorPolicy: 'ignore',
  //   },
  // },
})

export default client
