import React from 'react'
import MuiAlert from '@mui/lab/Alert'
import Snackbar from '@mui/material/Snackbar'

interface IState {
  isOnline: boolean,
  shouldShowNotification: boolean
}

class OfflineNotifier extends React.Component<unknown, IState> {
  timer: NodeJS.Timeout | undefined

  constructor(props) {
    super(props)
    this.state = {
      isOnline: navigator.onLine,
      shouldShowNotification: !navigator.onLine,
    }
  }

  componentDidMount() {
    window.addEventListener('online', () => {
      this.setState({ isOnline: true })
      this.timer = setTimeout(() => this.setState({ shouldShowNotification: false }), 3000)
    })
    window.addEventListener('offline', () => {
      this.setState({ isOnline: false, shouldShowNotification: true })
    })
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }

  render() {
    if (!this.state.shouldShowNotification) return null

    if (this.state.isOnline) {
      return (
        <Snackbar open anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <MuiAlert severity="success" elevation={6} variant="filled">
            <div>Back online</div>
          </MuiAlert>
        </Snackbar>
      )
    }
    return (
      <Snackbar open anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <MuiAlert severity="error" elevation={6} variant="filled">
          <div>You are offline. Please check the internet connection.</div>
        </MuiAlert>
      </Snackbar>
    )
  }
}

export default OfflineNotifier
