import React, { useEffect, useState } from 'react'
import type { TimeRangePickerProps } from 'antd'
import { DatePicker, Space, ConfigProvider } from 'antd'
import { get } from 'lodash'
import dayjs, { Dayjs } from 'dayjs'
import {
  Button, ClickAwayListener,
  IconButton,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  styled as styled1,
} from '@mui/material'
import 'antd/dist/reset.css'
import './styles.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import styled from 'styled-components'
import 'dayjs/locale/en'
import { compose } from 'redux'
import { RangeValueType } from 'rc-picker/lib/PickerInput/RangePicker'
import { ChildDataProps, graphql } from '@apollo/react-hoc'
import { gql } from '@apollo/client'
import { ICampaign } from 'graphql/graphql.d'
import {
  LeftOutlined, RightOutlined,
} from '@ant-design/icons'
import { findThisAndLastWeek } from './util'
import { formateMessage } from 'containers/Insight/utils'

interface IResponse {
  campaign: ICampaign
  getAllTimeFilterStatus: boolean
}
interface IOuterProps {
  selectedDateFrom: string,
  selectedDateTo: string,
  handleDateChange(startDate, endDate): void,
  startDate: string | Date,
  endDate: string | Date,
  huddleDateFilter: boolean,
  campaignId: string,
}

type IProps = ChildDataProps<unknown, IResponse>
type PanelMode = 'time' | 'date' | 'month' | 'year' | 'decade'

const { RangePicker } = DatePicker

const CustomRangePicker = styled(RangePicker)`
  display: flex
  .ant-picker-suffix {
    pointer-events: auto !important
    cursor: pointer !important;
  }
`

dayjs.locale('en')

const findSelectedPresetValue = (startDate, endDate, availabelPreSetRanges) => {
  const tempStartDate = dayjs(startDate)
  const tempEndDate = dayjs(endDate).endOf('day')
  let selectedPreset: TimeRangePickerProps['presets'] = []
  availabelPreSetRanges.forEach((val) => {
    if (val.value[0].isSame(tempStartDate) && val.value[1].isSame(tempEndDate)) {
      selectedPreset?.push(val)
    }
  })
  if (selectedPreset.length <= 0) {
    selectedPreset = [{
      label: 'custom',
      value: [tempStartDate, tempEndDate],
    }]
  }
  return selectedPreset
}

const parseDate = (input) => {
  const parts = input.split('/')
  const day = parseInt(parts[0], 10)
  const month = parseInt(parts[1], 10) - 1 // Months are zero-based in JavaScript's Date object
  const year = parseInt(parts[2], 10)
  return new Date(year, month, day)
}

const LightTooltip = styled1(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: '#CC0000',
    border: '1px solid red',
    maxWidth: 'none',
  },
}))

const DateRangeSelector: React.FC<IOuterProps & IProps> = (props) => {
  const availabelPreSetRanges: TimeRangePickerProps['presets'] = [
    {
      label: 'Today',
      value: [dayjs().startOf('day'), dayjs().endOf('day')],
    },
    {
      label: 'This Week',
      value: findThisAndLastWeek().this_week,
    },
    {
      label: 'Last Week',
      value: findThisAndLastWeek().last_week,
    },
    {
      label: 'This Month',
      value: [dayjs().startOf('month'), dayjs().endOf('day')],
    },
    {
      label: 'Last 30 Days',
      value: [dayjs().startOf('day').add(-30, 'd'), dayjs().endOf('day')],
    },
    {
      label: 'Last Month',
      value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    },
    {
      label: 'This Year',
      value: [dayjs().startOf('year'), dayjs().endOf('day')],
    },
    {
      label: 'This FY',
      value: [dayjs().month(3).startOf('month'), dayjs().endOf('day')],
    },
    {
      label: 'Last FY',
      value: [dayjs().startOf('year').subtract(1, 'year').month(3), dayjs().startOf('year').add(2, 'month').endOf('month')],
    },
  ]

  const defaultViewOfPreset: TimeRangePickerProps['presets'] = availabelPreSetRanges.slice(0, 5)
  const [selectedPredefinedRange, setSelectedPredefinedRange] = useState(() => (props.huddleDateFilter ? findSelectedPresetValue(props.startDate, props.endDate, availabelPreSetRanges) : findSelectedPresetValue(props.selectedDateFrom, props.selectedDateTo, availabelPreSetRanges)))
  const [presetRange, setPresetRange] = useState(defaultViewOfPreset)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [isExtraFooter, setExtraFooter] = useState(true)
  const [activeInputDate, setActiveInputDate] = useState<[boolean, boolean]>([false, false])
  const [activeMode, setActiveMode] = useState<[startMode: PanelMode, endMode: PanelMode]>(['date', 'date'])
  const [isError, setError] = useState('')
  const minDateForFilter = props.data.campaign?.campaignStartDate

  useEffect(() => {
    const getSelectedPresetValue = props.huddleDateFilter ? findSelectedPresetValue(props.startDate, props.endDate, availabelPreSetRanges) : findSelectedPresetValue(props.selectedDateFrom, props.selectedDateTo, availabelPreSetRanges)
    setSelectedPredefinedRange(getSelectedPresetValue)
  }, [props.huddleDateFilter, props.startDate, props.endDate, props.selectedDateFrom, props.selectedDateTo])

  useEffect(() => {
    if (props.data.getAllTimeFilterStatus && !availabelPreSetRanges.some((el) => el.label === 'All Time')) {
      availabelPreSetRanges.push(
        {
          label: 'All Time',
          value: [dayjs(minDateForFilter), dayjs().endOf('day')],
        },
      )
    }
  })
  const renderPredefinedRanges = (selected: any) => (
    <div className="grid grid-cols-5 gap-1 place-items-center mb-4 mt-2">
      {presetRange?.map((val, idx) => (
        <div key={`${val.label}`} className="items-center w-full">
          <Button
            sx={{
              width: '100%',
              borderRadius: 0,
              px: 2,
              py: 1,
              borderColor: 'divider',
              color: selected[0]?.label === val.label ? '#001C64' : 'inherit',
              borderBottom: selected[0]?.label === val.label ? '2px solid' : 'none',
              borderBottomColor: '#001C64',
              backgroundColor: selected[0]?.label === val.label ? '#EFF5FF' : '#F4F4F4',
              '&:hover': {
                backgroundColor: '#EFF5FF',
              },
              '&:focus': {
                outline: 'none',
                backgroundColor: '#EFF5FF',
              },
              '&:active': {
                outline: 'none',
                backgroundColor: '#EFF5FF',
              },
              textTransform: 'none',
            }}
            onClick={() => {
              setSelectedPredefinedRange([val])
              setError('')
            }}
          >
            {formateMessage(val.label, val.label)}
          </Button>
        </div>
      ))}
      {(!props.data.getAllTimeFilterStatus && presetRange.length > 5) ? <div /> : null}
      <div className="w-full">
        <IconButton
          size="small"
          sx={{
            textTransform: 'none',
            '&:active': {
              outline: 'none',
            },
            '&:focus': {
              outline: 'none',
            },
            width: '100%',
          }}
          onClick={() => {
            if (presetRange?.length === 5) {
              setPresetRange(availabelPreSetRanges)
            } else {
              setPresetRange(defaultViewOfPreset)
            }
          }}
        >
          {presetRange.length === 5 ? <KeyboardArrowDownIcon fontSize="small" sx={{ marginRight: '0.5rem' }} /> : <KeyboardArrowUpIcon fontSize="small" sx={{ marginRight: '0.5rem' }} />}
          {presetRange.length === 5 ? <span className="text-sm text-black">{formateMessage('Show More', 'Show More')}</span> : <span className="text-sm text-black">{formateMessage('Show Less', 'Show Less')}</span>}
        </IconButton>
      </div>
      <div className="w-full">{/** */}</div>
      <div className="w-full">{/** */}</div>
      <div className="w-full">
        <Button
          sx={{
            background: '#F4F4F4',
            color: 'black',
            width: '100%',
            '&:hover': {
              backgroundColor: 'action.selected',
            },
            '&:active': {
              outline: 'none',
              background: '#0230E8',
              transition: 'all .05s ease-in-out',
              color: '#fff',
            },
            '&:focus': {
              outline: 'none',
            },
            borderRadius: 'none',
          }}
          variant="contained"
          onClick={() => {
            setSelectedPredefinedRange(findSelectedPresetValue(props.selectedDateFrom, props.selectedDateTo, availabelPreSetRanges))
            setError('')
          }}
        >
        {formateMessage('Reset', 'Reset')}
        </Button>
      </div>
      <div className="w-full ml-2">
        <Button
          variant="contained"
          sx={{
            borderRadius: 'none',
            width: '100%',
            backgroundColor: '#0230E8',
          }}
          onClick={() => {
            if (!isError) {
              const tempStartDate = selectedPredefinedRange[0].value[0].format('YYYY-MM-DD')
              const tempEndDate = selectedPredefinedRange[0].value[1].format('YYYY-MM-DD')
              props.handleDateChange(tempStartDate, tempEndDate)
              setIsPopupOpen(false)
              setPresetRange(defaultViewOfPreset)
            }
          }}
        >
          {formateMessage('Apply', 'Apply')}
        </Button>
      </div>
    </div>
  )

  return (
    <ConfigProvider
      locale={{ locale: 'en' }}
      theme={{
        components: {
          DatePicker: {
            colorPrimary: '#050D77',
            cellActiveWithRangeBg: '#F3F3F6',
          },
        },
      }}
    >
      <ClickAwayListener
        onClickAway={() => {
          setSelectedPredefinedRange(findSelectedPresetValue(props.selectedDateFrom, props.selectedDateTo, availabelPreSetRanges))
          setPresetRange(defaultViewOfPreset)
          setIsPopupOpen(false)
          setActiveInputDate([false, false])
          setExtraFooter(true)
          setActiveMode(['date', 'date'])
          setError('')
        }}
      >
        <div>
          <LightTooltip
            title={isError ? <Typography sx={{ fontSize: '0.84rem' }}>{isError}</Typography> : null}
            placement="right-start"
          >
            <Space direction="horizontal" size={12}>
              <CustomRangePicker
                format={{
                  format: 'DD/MM/YYYY',
                }}
                onClick={() => setIsPopupOpen(true)}
                allowClear={false}
                open={isPopupOpen}
                value={selectedPredefinedRange[0]?.value as RangeValueType<Dayjs>}
                separator={<div style={{ width: '20px' }}><span>{formateMessage('to', 'to')}</span></div>}
                size="middle"
                className="custom-range-picker"
                onPanelChange={(value, mode: [startMode: PanelMode, endMode: PanelMode]) => {
                  if (mode[0] === 'date' && mode[1] === 'date') {
                    setExtraFooter(true)
                    setPresetRange(defaultViewOfPreset)
                    setActiveInputDate([false, false])
                  } else {
                    const indexOfDate = mode.indexOf('date')
                    const tempActiveInputDate: [boolean, boolean] = [false, false]
                    if (indexOfDate !== -1) tempActiveInputDate[indexOfDate] = true
                    setActiveInputDate(tempActiveInputDate)
                    setExtraFooter(false)
                  }
                  setActiveMode(mode)
                }}
                onCalendarChange={(dates, dateStrings) => {
                  const maxDateAllowed = new Date()
                  maxDateAllowed.setHours(23, 59, 59, 999)
                  const tempMinDateAllowed = new Date(minDateForFilter)
                  const minDateAllowed = new Date(tempMinDateAllowed.getFullYear(), tempMinDateAllowed.getMonth(), tempMinDateAllowed.getDate())
                  const userInputEndDate = parseDate(dateStrings[1])
                  userInputEndDate.setHours(23, 59, 59, 999)
                  const userInputStartDate = parseDate(dateStrings[0])
                  if (userInputEndDate > maxDateAllowed) {
                    setError('End Date cannot be later than the current date or month')
                  } else if (userInputEndDate < userInputStartDate) {
                    setError('End Date cannot be earlier than the Start Date.')
                  } else if (userInputStartDate < minDateAllowed) {
                    setError('Start Date cannot be earlier than the campaign creation date.')
                  } else if (userInputEndDate < minDateAllowed) {
                    setError('End Date cannot be earlier than the campaign creation date.')
                  } else if (userInputStartDate > maxDateAllowed) {
                    setError('Start Date cannot be later than the current date or month')
                  } else if (userInputStartDate > userInputEndDate) {
                    setError('Start Date cannot be later than the End Date.')
                  } else {
                    setError('')
                    setSelectedPredefinedRange([{ label: 'custom', value: dates }])
                  }
                }}
                maxDate={dayjs().endOf('d')}
                minDate={dayjs(minDateForFilter).startOf('d')}
                {...(isExtraFooter ? { renderExtraFooter: () => renderPredefinedRanges(selectedPredefinedRange) } : {})}
                style={{
                  height: '2.7rem',
                  maxWidth: '16.5rem',
                }}
                mode={activeMode}
                superPrevIcon={<LeftOutlined style={{ display: isExtraFooter ? 'none' : 'block' }} />}
                superNextIcon={<RightOutlined style={{ display: isExtraFooter ? 'none' : 'block' }} />}
                disabled={activeInputDate}
                status={isError ? 'error' : ''}
              />
            </Space>
          </LightTooltip>
        </div>
      </ClickAwayListener>
    </ConfigProvider>
  )
}

export default compose<React.ComponentType<IOuterProps>>(
  graphql(gql`
      query qy001a($campaignId: ID!) {
        campaign(campaignId: $campaignId) {
          campaignStartDate
        }
        getAllTimeFilterStatus(campaignId: $campaignId)
      }
    `, {
    options: (props: IOuterProps) => ({
      variables: {
        campaignId: props.campaignId,
      },
    }),
  }),
)(DateRangeSelector)
