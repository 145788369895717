import produce from 'immer'
import { Action } from 'redux'
import actionTypes from '../actions/types'

interface IAction extends Action {
  payload: {
    apiName: string,
    data: any,
  }
}

interface IReducerState {
  participantInfo: { [key: string]: any }
}

const initialState: IReducerState = {
  participantInfo: {
    isFetching: {},
    hasError: {},
    data: {},
  },
}

const participantInfoReducer = (state = initialState, action: IAction) =>
  produce(state, draft => {
    switch (action.type) {
      case actionTypes.participantInfoStart: {
        draft.participantInfo = {
          ...draft.participantInfo,
          isFetching: {
            ...draft.participantInfo.isFetching,
            [action.payload.apiName]: true,
          },
          hasError: {
            ...draft.participantInfo.hasError,
            [action.payload.apiName]: false,
          },
        }
        break
      }
      case actionTypes.participantInfoFailure: {
        draft.participantInfo = {
          ...draft.participantInfo,
          isFetching: {
            ...draft.participantInfo.isFetching,
            [action.payload.apiName]: false,
          },
          hasError: {
            ...draft.participantInfo.hasError,
            [action.payload.apiName]: true,
          },
        }
        break
      }
      case actionTypes.participantInfoSuccess: {
        draft.participantInfo = {
          ...draft.participantInfo,
          isFetching: {
            ...draft.participantInfo.isFetching,
            [action.payload.apiName]: false,
          },
          hasError: {
            ...draft.participantInfo.hasError,
            [action.payload.apiName]: false,
          },
          data: {
            ...draft.participantInfo.data,
            [action.payload.apiName]: action.payload.data,
          },
        }
        break
      }
      default:
    }
  })

export default participantInfoReducer
