import React from 'react'
import Skeleton from '@mui/lab/Skeleton'
import { formateMessage } from 'containers/Insight/utils'



const LoadingIndicator: React.FunctionComponent<any> = () => (
  <>
    <Skeleton variant="rectangular" height={80} width="100%" />
    <Skeleton variant="rectangular" height={80} width="100%" className="my-1" />
    <Skeleton variant="rectangular" height={80} width="100%" />
    <p style={{ paddingTop: '20px', fontSize: '16px', textAlign: 'center' }}>
      {formateMessage('Preparing data for you. Please wait', 'Preparing data for you. Please wait')}
      ..
      {' '}
    </p>
  </>
)


export default LoadingIndicator
