/* eslint-disable default-case */
// import { ApolloClient, gql } from '@apollo/client'

export function getCookieDomain() {
  const domain = window.location.hostname
  let cookieDomain = domain
  if (domain.split('.').length > 1) { // if the host has subdomain eg: auth3.zykrr.com
    cookieDomain = '.zykrr.com' // set cookieDomain to one level up eg: zykrr.com
  }
  return cookieDomain
}

export function setCookie(name, value, date) {
  let expires = ''
  if (date) {
    expires = `expires=${date.toUTCString()};`
  }

  const domain = getCookieDomain()

  let secureCookie = ''
  if (domain !== 'localhost') {
    secureCookie = 'Secure;'
  }

  document.cookie = `${name}=${value}; ${expires} domain=${domain}; path=/; ${secureCookie} SameSite=Lax;`
}

function isJsonString(str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const getCookieFromLocalstorage = (name) => {
  const itemStr = localStorage.getItem(name)

  // If the item doesn't exist, return null
  if (!itemStr) {
    return null
  }

  if (!isJsonString(itemStr)) return itemStr

  const item = JSON.parse(itemStr)
  const now = new Date()

  // Compare the expiry time of the item with the current time
  if (now.getTime() > new Date(item.expiry).getTime()) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(name)
    return null
  }
  return item.value
}

export function getCookie(name) {
  let cookieValue = getCookieFromLocalstorage(name) || null
  if (document.cookie) {
    const nameEQ = `${name}=`
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i]
      while (c.charAt(0) === ' ') c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) === 0) {
        cookieValue = c.substring(nameEQ.length, c.length)
        break
      }
    }
  }
  return cookieValue
}

export function eraseCookie(name) {
  const date = new Date()
  const days = 1000
  date.setTime(date.getTime() - days * 24 * 60 * 60 * 1000)
  document.cookie = `${name}=; domain=${getCookieDomain()}; expires=${date.toUTCString()}`
}

export function getQuestionnaireFromLocalStorage(id) {
  const getQuestionareValue = localStorage.getItem(`${id}-questionnaire`) || []
  if (getQuestionareValue && getQuestionareValue.length) {
    return JSON.parse(getQuestionareValue)
  } return ''
}

function isQuotaExceeded(e) {
  let quotaExceeded = false
  if (e) {
    if (e.code) {
      switch (e.code) {
        case 22:
          quotaExceeded = true
          break
        case 1014:
          // Firefox
          if (e.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
            quotaExceeded = true
          }
          break
      }
    } else if (e.number === -2147024882) {
      // Internet Explorer 8
      quotaExceeded = true
    }
  }
  return quotaExceeded
}

export const saveDataToLocalStorage = (key, data) => {
  const keys = Object.keys(localStorage)
  try {
    localStorage.setItem(key, JSON.stringify(data))
  } catch (e) {
    if (isQuotaExceeded(e)) {
      // Quota exceeded, delete keys containing "xyz" or "456"
      keys.forEach((key) => {
        if (key.includes('questionnaire')) {
          localStorage.removeItem(key)
        }
        if (key.includes('getDimensionAndUniqueAnswers')) {
          localStorage.removeItem(key)
        }
      })

      // Retry setting the item
      localStorage.setItem(key, JSON.stringify(data))
    } else {
      // Handle other localStorage errors
      console.error('Error setting item in local storage:', e)
    }
  }
}

export const setCookieInLocalstorage = (name, value, date) => {
  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value,
    expiry: date,
  }
  localStorage.setItem(name, JSON.stringify(item))
}

export const eraseCookieLocalStorage = (name) => localStorage.removeItem(name)
