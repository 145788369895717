/*
 *
 * LanguageProvider reducer
 *
 */

import produce from 'immer'

import { rightToLanguagesCode } from 'containers/Insight/utils'
import { CHANGE_LOCALE } from './constants'
import { DEFAULT_LOCALE } from '../../i18n'

export const initialState = {
  locale: DEFAULT_LOCALE,
}

/* eslint-disable default-case, no-param-reassign */
const languageProviderReducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case CHANGE_LOCALE:
      draft.locale = action.locale
      // change the browser content rendering direction
      document.body.dir = rightToLanguagesCode.includes(CHANGE_LOCALE)
        ? 'rtl'
        : 'ltr'
      break
  }
})

export default languageProviderReducer
