import produce from 'immer'

interface ReducerState {
  questionsForUserTable: Array<string>
}

const initialState: ReducerState = {
  questionsForUserTable: [],
}
const userTableQuestionsReducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case 'QUESTIONS_CHANGED': {
      draft.questionsForUserTable = action.payload.questionArray
      break
    }
    default:
  }
})

export default userTableQuestionsReducer
